@forward './variables';
@use './variables' as var;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Gill Sans;
    font-weight: 300;
    src: url(/assets/fonts/gill-sans/300-light.otf) format('otf');
  }
  @font-face {
    font-family: Gill Sans;
    font-weight: 400;
    src: url(/assets/fonts/gill-sans/400-normal.otf) format('otf');
  }
  @font-face {
    font-family: Gill Sans;
    font-weight: 500;
    src: url(/assets/fonts/gill-sans/500-medium.otf) format('otf');
  }
  @font-face {
    font-family: Gill Sans;
    font-weight: 600;
    src: url(/assets/fonts/gill-sans/600-bold.otf) format('otf');
  }
  @font-face {
    font-family: Gill Sans;
    font-weight: 700;
    src: url(/assets/fonts/gill-sans/700-heavy.otf) format('otf');
  }
  @font-face {
    font-family: Gill Sans;
    font-weight: 800;
    src: url(/assets/fonts/gill-sans/800-condensed.otf) format('otf');
  }
  @font-face {
    font-family: Gill Sans;
    font-weight: 900;
    src: url(/assets/fonts/gill-sans/900-condensed-bold.otf) format('otf');
  }
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// color ranges

$glps-primary-palette: (
  50: 'ff9e0',
  100: 'fff1b3',
  200: 'ffe880',
  300: 'ffdf4d',
  400: 'ffd826',
  500: var.$primary-color,
  600: 'ffcc00',
  700: 'ffc600',
  contrast: [],
);

$glps-accent-palette: (
  50: 'eee6f1',
  100: 'd4c1dc',
  200: 'b897c5',
  300: '9b6dad',
  400: '854e9c',
  500: var.$accent-color,
  600: '682a82',
  700: '5d2377',
  contrast: [],
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$glps-primary: mat.define-palette($glps-primary-palette);
$glps-accent: mat.define-palette($glps-accent-palette);

// The warn palette is optional (defaults to red).
$glps-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$glps-theme: mat.define-light-theme(
  (
    color: (
      primary: $glps-primary,
      accent: $glps-accent,
      warn: $glps-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($glps-theme);

/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  height: 100%;
  font-size: 15px;
  font-family: 'Gill Sans', sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Media query for laptops*/
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  html,
  body {
    font-size: 12px;
  }
}

/* Media query for desktop */
@media only screen and (min-width: 1367px) and (max-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}

/* Media query for larger desktops */
@media only screen and (min-width: 1920px) {
  html,
  body {
    font-size: 15px;
  }
}

@media print {
  body {
    visibility: hidden;
  }
}
